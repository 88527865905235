import {
  Cell,
  Grid,
} from 'baseui/layout-grid';
import {
  ParagraphSmall,
} from 'baseui/typography';
import {
  ChangeEvent,
  memo,
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { scalePrice, unScalePrice } from 'utils/priceScale';
import {
  colors,
  emptyPlaceholder,
} from 'theme';
import priceFormatter from 'utils/priceFormatter';
import { sourceAccountsMapper } from 'dataMappers/repaymentsDataMappers';
import {
  TableBuilder,
  TableBuilderColumn,
} from 'baseui/table-semantic';
import {
  fetchOrganizationRepaymentRequestsOnLoadMore,
  repaymentsOrganizationRepaymentRequestsAreFetchedSelector,
  repaymentsOrganizationRepaymentRequestsListSelector,
  repaymentsOrganizationRepaymentRequestsMorePagesSelector,
  repaymentsOrganizationRepaymentRequestsPendingListSelector,
  repaymentsOrganizationRepaymentRequestsSearchFromDateSelector,
  repaymentsOrganizationRepaymentRequestsSearchStatusSelector,
  repaymentsOrganizationRepaymentRequestsSearchToDateSelector,
  repaymentsOrganizationRepaymentRequestsSelectedOrganizationSelector,
  selectedRepaymentRequestsListSelector,
  selectedRepaymentRequestsOptionChosenSelector,
  setSelectedRepaymentRequests,
  fetchRepaymentsSourceAccounts,
  repaymentsOrganizationSourceAccountsListSelector,
  editRepayment,
} from 'store/slices/repaymentsBankDetails';
import { useHistory } from 'react-router-dom';
import { setLoggedOrganization } from 'store/slices/loggedOrganization';
import {
  FetchRepaymentsRequestsType,
  RepaymentRequestStatus,
  RepaymentRequestType,
  TAG_REPAYMENTS_STATUS_KINDS,
} from 'types/RepaymentsBankDetails';
import moment from 'moment';
import { Block } from 'baseui/block';
import {
  Button,
  KIND,
  SIZE,
} from 'baseui/button';
import { Tag } from 'baseui/tag';
import { Checkbox } from 'baseui/checkbox';
import AppTooltip from 'components/Form/AppTooltip';
import { TETHER_PLACEMENT } from 'baseui/layer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchRepayment, setRepayment, setRepaymentSource } from 'store/slices/repayments';
import { useStyletron } from 'baseui';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { OnChangeParams, Select, SIZE as SelectSize } from 'baseui/select';
import { Input } from 'baseui/input';
import { InputCustomHTMLElement } from 'types/CommonTypes';
import { FormControl } from 'baseui/form-control';

const ACHDebitSectionTable = ({
  fetchRepaymentRequests,
}: { fetchRepaymentRequests: () => void }) => {
  const history = useHistory();
  const { t } = useTranslation(['treasury, common, dateFormats']);
  const dateFormat = t('dateFormats:standard-with-time');
  const dispatch = useAppDispatch();
  const [css] = useStyletron();

  const repaymentRequests = useAppSelector(repaymentsOrganizationRepaymentRequestsListSelector);
  const morePagesAvailable = useAppSelector(repaymentsOrganizationRepaymentRequestsMorePagesSelector);
  const pending = useAppSelector(repaymentsOrganizationRepaymentRequestsPendingListSelector);
  const areFetched = useAppSelector(repaymentsOrganizationRepaymentRequestsAreFetchedSelector);

  const repaymentStatus = useAppSelector(repaymentsOrganizationRepaymentRequestsSearchStatusSelector);
  const dateFormatStandard = t('dateFormats:standard-reverse');
  const organization = useAppSelector(repaymentsOrganizationRepaymentRequestsSelectedOrganizationSelector);
  const fromDate = useAppSelector(repaymentsOrganizationRepaymentRequestsSearchFromDateSelector);
  const toDate = useAppSelector(repaymentsOrganizationRepaymentRequestsSearchToDateSelector);
  const sourceAccounts = useAppSelector(repaymentsOrganizationSourceAccountsListSelector);

  const isSingleRequestChecked = useAppSelector(selectedRepaymentRequestsOptionChosenSelector);
  const selectedRequests = useAppSelector(selectedRepaymentRequestsListSelector);

  const [editedRepaymentsRequest, setEditedRepaymentsRequest] = useState<any>(null);
  const [amountError, setAmountError] = useState<string | null>(null);

  useEffect(() => {
    organization && organization[0]?.value && dispatch(fetchRepaymentsSourceAccounts({ organizationID: organization[0]?.value }));
  }, [organization]);

  const mappedSourceAccounts = sourceAccountsMapper(sourceAccounts);

  const repaymentStatusNoProcessing = useMemo(() => repaymentStatus
    && repaymentStatus.length > 0
    && (repaymentStatus[0].value !== RepaymentRequestStatus.NEW
      && repaymentStatus[0].value !== RepaymentRequestStatus.REJECTED_BY_BANK
    ), [repaymentStatus]);

  const repaymentRequestsNoProcessing = useMemo(
    () => repaymentRequests && repaymentRequests.length > 0
    && repaymentRequests.some((request: RepaymentRequestType) => request.status !== RepaymentRequestStatus.NEW
      && request.status !== RepaymentRequestStatus.REJECTED_BY_BANK),
    [repaymentRequests],
  );

  const isEditAllowed = (status: string | undefined): boolean => {
    const allowedStatuses = [
      RepaymentRequestStatus.NEW,
      RepaymentRequestStatus.INVALID,
      RepaymentRequestStatus.REJECTED_BY_BANK,
    ];

    return allowedStatuses.includes(status as RepaymentRequestStatus);
  };

  const isSaveAllowed = (repaymentRequest: RepaymentRequestType): boolean => {
    const hasAmountChanged = editedRepaymentsRequest.amount.value !== repaymentRequest.amount.value;
    const hasSourceAccountChanged = editedRepaymentsRequest.sourceAccountNickname !== repaymentRequest.sourceAccountNickname;

    const canSave = !amountError && (hasAmountChanged || hasSourceAccountChanged);

    return canSave;
  };

  const hasAny = Boolean(repaymentRequests?.length);
  const hasAll = useMemo(
    () => hasAny && (repaymentRequests?.every((x) => x.selected) || repaymentRequests?.every((x) => selectedRequests.find((i) => i.id === x.id))),
    [repaymentRequests,
      selectedRequests,
      hasAny],
  );
  const hasSome = useMemo(
    () => hasAny && repaymentRequests?.some((x) => x.selected),
    [repaymentRequests,
      hasAny],
  );

  const toggleAll = () => {
    const filteredAndRemapped = repaymentRequests?.map((request) => ({
      ...request,
      selected: !hasAll,
    }));
    if (!hasAll) {
      dispatch(setSelectedRepaymentRequests(selectedRequests
        .concat(filteredAndRemapped?.filter((request) => !selectedRequests.find((row) => String(row.id) === String(request.id))))));
    } else {
      dispatch(setSelectedRepaymentRequests(selectedRequests
        .filter((request) => !repaymentRequests?.find((row) => String(row.id) === String(request.id)))));
    }
  };

  const toggle = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, checked } = event.currentTarget;

    const targetedRequest = repaymentRequests?.find((row) => String(row.id) === name);
    if (checked && targetedRequest) {
      dispatch(setSelectedRepaymentRequests(selectedRequests.concat([targetedRequest])));
    } else {
      dispatch(setSelectedRepaymentRequests(selectedRequests.filter(({ id }) => targetedRequest && targetedRequest.id !== id)));
    }
  };

  const handleLoadMoreClick = () => {
    if ((organization && organization[0]?.value)) {
      const filter = new Map([
        ['organizationID', organization[0].value],
        ['status', repaymentStatus?.[0]?.value || ''],
        ['startDate', fromDate ? moment(fromDate).format(dateFormatStandard) : ''],
        ['endDate', toDate ? moment(toDate).format(dateFormatStandard) : ''],
      ]);
      dispatch(fetchOrganizationRepaymentRequestsOnLoadMore(Object.fromEntries(filter) as FetchRepaymentsRequestsType));
    }
  };

  const navigateToRepayment = (repaymentRequest: RepaymentRequestType) => {
    dispatch(setLoggedOrganization({
      name: organization[0].label,
      id: organization[0].value,
    }));
    dispatch(fetchRepayment({
      organizationId: organization[0]?.value,
      repaymentId: repaymentRequest.repaymentId,
    })).then(({ payload }) => {
      dispatch(setRepayment(payload));
      dispatch(setRepaymentSource('treasury'));
      history.push(`/repayments/${payload.id}`);
    });
  };

  const getAmount = (repaymentRequest: RepaymentRequestType) => (
    repaymentRequest.amount
      ? priceFormatter().format(unScalePrice(repaymentRequest.amount.value, repaymentRequest.amount.scale))
      : emptyPlaceholder
  );

  const validateAmountWithMax = (input: string, maxValue: number): boolean => {
    const regex = /^[0-9]+([.,]\d{2})$/;

    if (!regex.test(input)) {
      return false;
    }

    const numericValue = scalePrice(Number(input), editedRepaymentsRequest.amount.scale);

    return numericValue <= maxValue;
  };

  const handleChangeAmount = (
    e: ChangeEvent<InputCustomHTMLElement>,
    repaymentRequest: RepaymentRequestType,
  ) => {
    const newScaledValue = e.target.value ? scalePrice(Number(e.target.value), editedRepaymentsRequest.amount.scale) : '';

    if (!newScaledValue || !validateAmountWithMax(e.target.value, Number(repaymentRequest.amount.value))) {
      setAmountError(
        `${t('repayments:amountError')} ${priceFormatter().format(unScalePrice(repaymentRequest.amount.value, repaymentRequest.amount.scale))}`,
      );
    } else {
      setAmountError(null);
    }

    setEditedRepaymentsRequest((prevState: any) => ({
      ...prevState,
      amount: {
        value: newScaledValue,
        scale: editedRepaymentsRequest.amount.scale,
        currencyCode: editedRepaymentsRequest.amount.currencyCode,
      },
    }));
  };

  const handleChangeSourceAccount = (
    { option }: OnChangeParams,
  ) => {
    const sourceAccountId = Number(option?.value);
    const label = typeof option?.label === 'string' ? option?.label : '';
    const sourceAccountNickname = label.includes('-') ? label.split('-')[0].trim() : label;

    setEditedRepaymentsRequest((prevState: any) => ({
      ...prevState,
      sourceAccountId,
      sourceAccountNickname,
    }));
  };

  const handleEditRepayment = () => {
    editedRepaymentsRequest && dispatch(editRepayment({
      organizationID: organization[0]?.value,
      requestId: editedRepaymentsRequest.id,
      data: {
        amount: {
          value: editedRepaymentsRequest.amount.value,
          scale: editedRepaymentsRequest.amount.scale,
          currencyCode: editedRepaymentsRequest.amount.currencyCode,
        },
        sourceAccountId: editedRepaymentsRequest.sourceAccountId,
      },
    })).then(() => {
      setEditedRepaymentsRequest(null);
      fetchRepaymentRequests();
    });
  };

  const getEditableAmount = (repaymentRequest: RepaymentRequestType) => (
    <FormControl
      error={amountError}
      overrides={{
        ControlContainer: {
          style: () => ({
            marginBottom: '0',
          }),
          props: {
            'data-testid': 'FormControl-ach-debit-repayment-request',
            id: 'FormControl-ach-debit-repayment-request',
          },
        },
      }}
    >
      <Input
        size={SIZE.compact}
        autoComplete="off"
        type="number"
        step={0.01}
        min={0}
        error={!!amountError}
        max={unScalePrice(repaymentRequest.amount.value, repaymentRequest.amount.scale)}
        name="repaymentRequestAmount"
        endEnhancer={repaymentRequest.amount.currencyCode || 'USD'}
        onChange={(e) => handleChangeAmount(e, repaymentRequest)}
        value={editedRepaymentsRequest.amount.value
          ? unScalePrice(editedRepaymentsRequest.amount.value, editedRepaymentsRequest.amount.scale)
          : editedRepaymentsRequest.amount.value}
        placeholder={t('repayments:amount.label')}
        id="ach-debit-repayment-request-amount-input"
        overrides={{
          InputContainer: {
            style: {
              minWidth: '150px',
            },
          },
        }}
      />
    </FormControl>
  );

  const getEditableSourceAccounts = () => (
    <Select
      size={SelectSize.compact}
      placeholder={t('common:select')}
      type="select"
      searchable={false}
      options={mappedSourceAccounts}
      labelKey="label"
      valueKey="value"
      clearable={false}
      onChange={handleChangeSourceAccount}
      value={[{
        label: editedRepaymentsRequest?.sourceAccountNickname
          ? `${editedRepaymentsRequest?.sourceAccountNickname} - ${editedRepaymentsRequest?.sourceAccountLastFour}`
          : emptyPlaceholder,
        value: editedRepaymentsRequest.sourceAccountLastFour || null,
      }]}
      overrides={{
        ControlContainer: {
          props: {
            id: 'ach-debit-source-accounts--select',
          },
        },
      }}
    />
  );

  return (
    <Grid
      gridColumns={12}
      gridMargins={0}
    >
      <Cell
        span={12}
      >
        <TableBuilder
          data={repaymentRequests}
          emptyMessage={t('treasury:achDebit.repaymentsRequests.noResults')}
        >
          {isSingleRequestChecked && (
          <TableBuilderColumn
            header={(
              <Checkbox
                checked={hasAll}
                isIndeterminate={!hasAll && hasSome}
                disabled={repaymentStatusNoProcessing || repaymentRequestsNoProcessing}
                onChange={toggleAll}
                overrides={{
                  Root: {
                    props: {
                      id: 'repayments-requests-has-all-checkbox',
                    },
                  },
                  Input: {
                    props: {
                      'aria-checked': `${hasAll}`,
                    },
                  },
                }}
              />
            )}
          >
            {(request: RepaymentRequestType) => (
              <ParagraphSmall>
                <Checkbox
                  name={request.id.toString()}
                  checked={!!selectedRequests.find(({ id }) => id === request.id)}
                  onChange={toggle}
                  disabled={
                    request.status !== RepaymentRequestStatus.NEW
                    && request.status !== RepaymentRequestStatus.REJECTED_BY_BANK
                  }
                  overrides={{
                    Root: {
                      props: {
                        id: `repayments-requests-${request.id}-checkbox`,
                        'data-testid': `repayments-requests-${request.id}-checkbox`,
                      },
                    },
                    Input: {
                      props: {
                        'aria-checked': `${!!selectedRequests.find(({ id }) => id === request.id)}`,
                        'data-testid': `repayments-requests-${request.id}-checkbox-input`,
                      },
                    },
                  }}
                />
              </ParagraphSmall>
            )}
          </TableBuilderColumn>
          )}

          <TableBuilderColumn
            header={t('treasury:achDebit.repaymentRequest.table.repaymentName')}
          >
            {(repaymentRequest: RepaymentRequestType) => (
              <Block
                overrides={{
                  Block: {
                    style: {
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: '6px',
                    },
                  },
                }}
              >
                <ParagraphSmall
                  onClick={() => navigateToRepayment(repaymentRequest)}
                  color={colors.primary}
                  overrides={{
                    Block: {
                      style: {
                        cursor: 'pointer',
                      },
                    },
                  }}
                >
                  {repaymentRequest?.repaymentName || emptyPlaceholder}
                </ParagraphSmall>
              </Block>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('treasury:achDebit.repaymentRequest.table.amount')}
          >

            {(repaymentRequest: RepaymentRequestType) => (
              repaymentRequest.id && editedRepaymentsRequest?.id === repaymentRequest.id
                ? (
                  <Block
                    marginTop="7px"
                  >
                    {getEditableAmount(repaymentRequest)}
                  </Block>
                )
                : (
                  <ParagraphSmall>
                    {getAmount(repaymentRequest)}
                  </ParagraphSmall>
                )
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('treasury:achDebit.repaymentRequest.table.status')}
          >
            {(repaymentRequest: RepaymentRequestType) => {
              const kindType = TAG_REPAYMENTS_STATUS_KINDS[repaymentRequest.status as keyof typeof TAG_REPAYMENTS_STATUS_KINDS];
              return (
                <Tag
                  closeable={false}
                  kind={kindType as any}
                  overrides={{
                    Root: {
                      style: {
                        marginTop: '12px',
                      },
                    },
                    Text: {
                      style: {
                        maxWidth: 'unset',
                      },
                    },
                  }}
                >
                  {`${t(`treasury:achDebit.repaymentRequest.statuses.${repaymentRequest.status}`)}`}
                </Tag>
              );
            }}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={(
              <>
                {t('treasury:achDebit.repaymentRequest.table.requestedAt')}
                <AppTooltip
                  title={t('treasury:achDebit.repaymentRequest.table.requestedAtTooltip')}
                  placement={TETHER_PLACEMENT.right}
                />
              </>
            )}
          >
            {(repaymentRequest: RepaymentRequestType) => (
              <ParagraphSmall>
                { moment(repaymentRequest?.requestedAt).format(dateFormat) || emptyPlaceholder}
              </ParagraphSmall>
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('treasury:achDebit.repaymentRequest.table.sourceAccount')}
          >
            {(repaymentRequest: RepaymentRequestType) => (
              repaymentRequest.id && editedRepaymentsRequest?.id === repaymentRequest.id
                ? (
                  <Block
                    marginTop="7px"
                  >
                    {getEditableSourceAccounts()}
                  </Block>
                )
                : (
                  <ParagraphSmall>
                    {repaymentRequest?.sourceAccountNickname
                      ? `${repaymentRequest?.sourceAccountNickname} - ${repaymentRequest?.sourceAccountLastFour}`
                      : emptyPlaceholder}
                  </ParagraphSmall>
                )
            )}
          </TableBuilderColumn>

          <TableBuilderColumn
            header={t('treasury:achDebit.repaymentRequest.table.actions')}
          >
            {(repaymentRequest: RepaymentRequestType) => (
              repaymentRequest.id && editedRepaymentsRequest?.id === repaymentRequest.id
                ? (
                  <Block
                    display="flex"
                    justifyContent="flex-start"
                    gridGap="5px"
                    marginTop="10px"
                    marginBottom="10px"
                  >
                    <Button
                      kind={KIND.secondary}
                      size={SIZE.mini}
                      overrides={{
                        Root: {
                          props: { id: `ach-debit-edit-cancel-button-${repaymentRequest?.id}` },
                        },
                      }}
                      onClick={() => {
                        setEditedRepaymentsRequest(null);
                      }}
                    >
                      {t('common:cancel')}
                    </Button>
                    <Button
                      kind={KIND.primary}
                      size={SIZE.mini}
                      overrides={{
                        Root: {
                          props: { id: `ach-debit-save-button-${repaymentRequest?.id}` },
                        },
                      }}
                      disabled={!isSaveAllowed(repaymentRequest)}
                      onClick={handleEditRepayment}
                    >
                      {t('common:save')}
                    </Button>
                  </Block>
                )
                : (
                  <Block
                    display="flex"
                    justifyContent="flex-start"
                    gridGap="5px"
                    marginTop="18px"
                    marginBottom="18px"
                  >
                    <FontAwesomeIcon
                      className={
                        css({
                          cursor: isEditAllowed(repaymentRequest?.status) ? 'pointer' : 'not-allowed',
                          justifyContent: 'center',
                          color: isEditAllowed(repaymentRequest?.status) ? '#bb092f' : '#AFAFAF',
                        })
                      }
                      icon={faPencil}
                      onClick={() => isEditAllowed(repaymentRequest?.status) && setEditedRepaymentsRequest(repaymentRequest)}
                    />
                  </Block>
                )
            )}

          </TableBuilderColumn>
        </TableBuilder>
        <Block margin="10px 0 20px" display="flex">
          <Button
            overrides={{
              Root: {
                style: {
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                },
                props: {
                  id: 'repayments-requests-more-pages-button',
                },
              },
              LoadingSpinner: {
                style: {
                  borderRightColor: 'white',
                  borderTopColor: 'white',
                  borderLeftColor: 'white',
                },
              },
            }}
            isLoading={areFetched && pending}
            disabled={!morePagesAvailable || pending}
            onClick={handleLoadMoreClick}
          >
            {t('treasury:morePages')}
          </Button>
        </Block>
      </Cell>
    </Grid>
  );
};

export default memo(ACHDebitSectionTable);
