import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from 'store';
import { Option } from 'baseui/select';
import {
  destinationAccountsRequest,
  fetchOrganizationRepaymentRequestsRequest,
  fetchOrganizationRepaymentsAccountsRequest,
  saveSourceAccountRequest,
  deleteSourceAccountRequest,
  processRepaymentRequestsRequest,
  editSourceAccountRequest,
  editRepaymentRequest,
  fetchOrganizationSettingsRequest,
  saveOrganizationSettingsRequest,
} from 'repaymentsApi/repaymentsAPI';
import { resetOrganizationEvent } from 'store/events';
import {
  DeleteSourceAccountType,
  DestinationAccountType,
  EditRepaymentRequestParamsType,
  FetchOrganizationSettingsParamsType,
  FetchRepaymentsRequestsType,
  FetchRepaymentsSourceAccountsParamsType,
  OrganizationSettingsType,
  ProcessRepaymentRequestParamsType,
  RepaymentRequestType,
  SaveOrganizationSettingsParamsType,
  SourceAccountType,
} from 'types/RepaymentsBankDetails';

export const initialState = {
  sourceAccounts: {
    list: [] as SourceAccountType[],
    pending: false,
    isListFetched: false,
    isAccountCreatedOrEdited: false,
    isAccountDeleted: false,
  },
  repaymentRequests: {
    list: [] as RepaymentRequestType[],
    pending: false,
    isListFetched: false,
    morePages: false,
    page: 1,
  },
  isSelectedRepaymentRequestsOptionChosen: false,
  selectedRepaymentRequests: {
    list: [] as RepaymentRequestType[],
  },
  repaymentRequestsSelectedOrganization: [] as Option[],
  repaymentRequestsSearch: {
    fromDate: '' as any,
    toDate: '' as any,
    repaymentStatus: [] as any,
  },
  destinationAccounts: {
    list: [] as DestinationAccountType[],
    fetchFailed: false,
  },
  processAction: {
    errors: [],
    pending: false,
  },
  organizationSettings: {
    data: null as OrganizationSettingsType | null,
    pending: false,
    fetchFailed: false,
  },
};

export const fetchRepaymentsSourceAccounts = createAsyncThunk(
  'repaymentsBankDetails/sourceAccounts',
  async (
    params: FetchRepaymentsSourceAccountsParamsType,
    { getState, rejectWithValue },
  ): Promise<any> => {
    const { organizationID } = params;
    const storeState = getState() as RootState;

    try {
      const result = await fetchOrganizationRepaymentsAccountsRequest(
        storeState.user.accessToken,
        organizationID,
      );
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationRepaymentRequests = createAsyncThunk(
  'repaymentsBankDetails/repaymentRequests',
  async (params: FetchRepaymentsRequestsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID,
      startDate,
      endDate,
      sort,
      status,
    } = params;
    const {
      user: { accessToken },
      repaymentsBankDetails: { repaymentRequests },
    } = getState() as RootState;

    const page = repaymentRequests.page.toString();
    try {
      const result = await fetchOrganizationRepaymentRequestsRequest(
        accessToken,
        organizationID,
        page,
        startDate,
        endDate,
        status,
        sort,
      );
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationRepaymentRequestsOnLoadMore = createAsyncThunk(
  'repaymentsBankDetails/repaymentRequestsOnLoadMore',
  async (params: FetchRepaymentsRequestsType, { getState, rejectWithValue }): Promise<any> => {
    const {
      organizationID,
      startDate,
      endDate,
      sort,
      status,
    } = params;
    const {
      user: { accessToken },
      repaymentsBankDetails: { repaymentRequests },
    } = getState() as RootState;

    const page = (Number(repaymentRequests.page) + 1).toString();

    try {
      return await fetchOrganizationRepaymentRequestsRequest(
        accessToken,
        organizationID,
        page,
        startDate,
        endDate,
        status,
        sort,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchDestinationAccounts = createAsyncThunk(
  'organizations/fetchDestinationAccounts',
  async (
    _action,
    {
      getState,
      rejectWithValue,
    },
  ): Promise<any> => {
    const storeState = getState() as RootState;

    try {
      const result = await destinationAccountsRequest(
        storeState.user.accessToken,
      );

      return result?.values;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const saveSourceAccount = createAsyncThunk(
  'organizations/saveSourceAccount',
  async (params: any, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, data } = params;
    const storeState = getState() as RootState;

    try {
      return await saveSourceAccountRequest(
        storeState.user.accessToken,
        organizationID,
        data,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editSourceAccount = createAsyncThunk(
  'organizations/editSourceAccount',
  async (params: any, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, accountID, data } = params;
    const storeState = getState() as RootState;

    try {
      return await editSourceAccountRequest(
        storeState.user.accessToken,
        organizationID,
        accountID,
        data,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deleteSourceAccount = createAsyncThunk(
  'organizations/deleteSourceAccount',
  async (params: DeleteSourceAccountType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, accountID } = params;
    const storeState = getState() as RootState;

    try {
      return await deleteSourceAccountRequest(
        storeState.user.accessToken,
        organizationID,
        accountID,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const processRepaymentRequests = createAsyncThunk(
  'repaymentsBankDetails/processRepaymentRequests',
  async (params: ProcessRepaymentRequestParamsType, { dispatch, getState, rejectWithValue }): Promise<any> => {
    const { organizationID, data } = params;
    const storeState = getState() as RootState;

    try {
      const result = await processRepaymentRequestsRequest(
        storeState.user.accessToken,
        organizationID,
        data,
      );

      await dispatch(fetchOrganizationRepaymentRequests({ organizationID }));
      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editRepayment = createAsyncThunk(
  'repayment-requests/editRepaymentRequest',
  async (params: EditRepaymentRequestParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, requestId, data } = params;
    const storeState = getState() as RootState;

    try {
      return await editRepaymentRequest(
        storeState.user.accessToken,
        organizationID,
        requestId,
        data,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchOrganizationSettings = createAsyncThunk(
  'organizations/fetchOrganizationSettings',
  async (params: FetchOrganizationSettingsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchOrganizationSettingsRequest(
        storeState.user.accessToken,
        organizationID,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const saveOrganizationSettings = createAsyncThunk(
  'organizations/saveOrganizationSettings',
  async (params: SaveOrganizationSettingsParamsType, { getState, rejectWithValue }): Promise<any> => {
    const { organizationID, data } = params;
    const storeState = getState() as RootState;

    try {
      return await saveOrganizationSettingsRequest(
        storeState.user.accessToken,
        organizationID,
        data,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const repaymentsBankDetailsSlice = createSlice({
  name: 'repaymentsBankDetails',
  initialState,
  reducers: {
    setSourceAccountsList: (state, action) => {
      state.sourceAccounts.list = action.payload;
    },
    setOrganizationRepaymentRequestsList: (state, action) => {
      state.repaymentRequests.list = action.payload;
    },
    setOrganizationRepaymentRequestsSelectedOrganization: (state, action) => {
      state.repaymentRequestsSelectedOrganization = action.payload;
    },
    resetOrganizationRepaymentRequestsSelectedOrganization: (state) => {
      state.repaymentRequestsSelectedOrganization = initialState.repaymentRequestsSelectedOrganization;
    },
    setOrganizationRepaymentRequestsSearchFromDate: (state, action) => {
      state.repaymentRequestsSearch.fromDate = action.payload;
    },
    setOrganizationRepaymentRequestsSearchToDate: (state, action) => {
      state.repaymentRequestsSearch.toDate = action.payload;
    },
    setOrganizationRepaymentRequestsSearchStatus: (state, action) => {
      state.repaymentRequestsSearch.repaymentStatus = action.payload;
    },
    setSelectedRepaymentRequestsOptionChosen: (state, action) => {
      state.isSelectedRepaymentRequestsOptionChosen = action.payload;
    },
    setSelectedRepaymentRequests: (state, action) => {
      state.selectedRepaymentRequests.list = action.payload;
    },
    resetSelectedRepaymentRequests: (state) => {
      state.selectedRepaymentRequests.list = initialState.selectedRepaymentRequests.list;
    },
    resetOrganizationRepaymentRequestsSearch: (state) => {
      state.repaymentRequestsSearch = initialState.repaymentRequestsSearch;
    },
    resetSourceAccountsCreationStatus: (state) => {
      state.sourceAccounts.isAccountCreatedOrEdited = false;
    },
    setOrganizationSettings: (state, action) => {
      state.organizationSettings.data = action.payload;
      state.organizationSettings.pending = false;
      state.organizationSettings.fetchFailed = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRepaymentsSourceAccounts.pending, (state) => {
      state.sourceAccounts.pending = true;
      state.sourceAccounts.list = initialState.sourceAccounts.list;
    });

    builder.addCase(
      fetchRepaymentsSourceAccounts.fulfilled,
      (state, action) => {
        state.sourceAccounts.list = action.payload.values;
        state.sourceAccounts.pending = false;
        state.sourceAccounts.isListFetched = true;
      },
    );

    builder.addCase(fetchRepaymentsSourceAccounts.rejected, (state) => {
      state.sourceAccounts.list = initialState.sourceAccounts.list;
      state.sourceAccounts.pending = false;
    });

    builder.addCase(fetchOrganizationRepaymentRequests.pending, (state) => {
      state.repaymentRequests.pending = true;
      state.repaymentRequests.isListFetched = false;
      state.repaymentRequests.page = initialState.repaymentRequests.page;
    });

    builder.addCase(fetchOrganizationRepaymentRequests.fulfilled, (state, { payload: { values, morePages } }) => {
      state.repaymentRequests.list = values;
      state.repaymentRequests.pending = false;
      state.repaymentRequests.page = initialState.repaymentRequests.page;
      state.repaymentRequests.isListFetched = true;
      state.repaymentRequests.morePages = !!morePages;
    });

    builder.addCase(fetchOrganizationRepaymentRequests.rejected, (state) => {
      state.repaymentRequests.pending = false;
    });

    builder.addCase(fetchOrganizationRepaymentRequestsOnLoadMore.pending, (state) => {
      state.repaymentRequests.pending = true;
    });

    builder.addCase(fetchOrganizationRepaymentRequestsOnLoadMore.fulfilled, (state, { payload: { values, morePages } }) => {
      state.repaymentRequests.list = state.repaymentRequests.list.concat(values);
      state.repaymentRequests.pending = false;
      state.repaymentRequests.page += 1;
      state.repaymentRequests.isListFetched = true;
      state.repaymentRequests.morePages = !!morePages;
    });

    builder.addCase(fetchOrganizationRepaymentRequestsOnLoadMore.rejected, (state) => {
      state.repaymentRequests.pending = false;
    });

    builder.addCase(resetOrganizationEvent, () => initialState);

    builder.addCase(fetchDestinationAccounts.pending, (state) => {
      state.destinationAccounts = initialState.destinationAccounts;
    });

    builder.addCase(fetchDestinationAccounts.fulfilled, (state, action) => {
      state.destinationAccounts.list = action.payload;
      state.destinationAccounts.fetchFailed = false;
    });

    builder.addCase(saveSourceAccount.fulfilled, (state) => {
      state.sourceAccounts.isAccountCreatedOrEdited = true;
    });

    builder.addCase(saveSourceAccount.rejected, (state) => {
      state.sourceAccounts.isAccountCreatedOrEdited = false;
    });

    builder.addCase(editSourceAccount.fulfilled, (state) => {
      state.sourceAccounts.isAccountCreatedOrEdited = true;
    });

    builder.addCase(editSourceAccount.rejected, (state) => {
      state.sourceAccounts.isAccountCreatedOrEdited = false;
    });

    builder.addCase(deleteSourceAccount.pending, (state) => {
      state.sourceAccounts.isAccountDeleted = false;
    });

    builder.addCase(deleteSourceAccount.fulfilled, (state) => {
      state.sourceAccounts.isAccountDeleted = true;
    });

    builder.addCase(deleteSourceAccount.rejected, (state) => {
      state.sourceAccounts.isAccountDeleted = false;
    });

    builder.addCase(fetchDestinationAccounts.rejected, (state) => {
      state.destinationAccounts.list = [];
      state.destinationAccounts.fetchFailed = true;
    });

    builder.addCase(processRepaymentRequests.pending, (state) => {
      state.processAction.errors = initialState.processAction.errors;
      state.processAction.pending = true;
    });

    builder.addCase(processRepaymentRequests.fulfilled, (state, { payload }) => {
      state.processAction.errors = payload.errors;
      state.selectedRepaymentRequests = initialState.selectedRepaymentRequests;
      state.processAction.pending = false;
    });

    builder.addCase(processRepaymentRequests.rejected, (state) => {
      state.processAction.errors = initialState.processAction.errors;
      state.selectedRepaymentRequests = initialState.selectedRepaymentRequests;
      state.processAction.pending = initialState.processAction.pending;
    });

    builder.addCase(fetchOrganizationSettings.pending, (state) => {
      state.organizationSettings.pending = true;
      state.organizationSettings.fetchFailed = false;
    });

    builder.addCase(fetchOrganizationSettings.fulfilled, (state, action) => {
      state.organizationSettings.data = action.payload;
      state.organizationSettings.pending = false;
      state.organizationSettings.fetchFailed = false;
    });

    builder.addCase(fetchOrganizationSettings.rejected, (state) => {
      state.organizationSettings.data = null;
      state.organizationSettings.fetchFailed = true;
    });
  },
});

export const {
  setSourceAccountsList,
  setOrganizationRepaymentRequestsSelectedOrganization,
  resetOrganizationRepaymentRequestsSelectedOrganization,
  setOrganizationRepaymentRequestsSearchFromDate,
  setOrganizationRepaymentRequestsSearchToDate,
  setOrganizationRepaymentRequestsSearchStatus,
  resetOrganizationRepaymentRequestsSearch,
  setOrganizationRepaymentRequestsList,
  resetSourceAccountsCreationStatus,
  setSelectedRepaymentRequestsOptionChosen,
  setSelectedRepaymentRequests,
  resetSelectedRepaymentRequests,
  setOrganizationSettings,
} = repaymentsBankDetailsSlice.actions;

export const repaymentsOrganizationRepaymentRequestsSelectedOrganizationSelector = (state
  : RootState) => state.repaymentsBankDetails.repaymentRequestsSelectedOrganization;
export const repaymentsOrganizationSourceAccountsListSelector = (state: RootState): SourceAccountType[] => state.repaymentsBankDetails.sourceAccounts.list;
export const repaymentsOrganizationSourceAccountsPendingSelector = (state: RootState): boolean => state.repaymentsBankDetails.sourceAccounts.pending;
export const repaymentsOrganizationSourceAccountsIsListFetchedSelector = (state: RootState)
: boolean => state.repaymentsBankDetails.sourceAccounts.isListFetched;
export const repaymentsOrganizationRepaymentRequestsListSelector = (state: RootState)
: RepaymentRequestType[] => state.repaymentsBankDetails.repaymentRequests.list;
export const repaymentsOrganizationRepaymentRequestsPendingListSelector = (state: RootState)
: boolean => state.repaymentsBankDetails.repaymentRequests.pending;
export const repaymentsOrganizationRepaymentRequestsAreFetchedSelector = (state: RootState)
: boolean => state.repaymentsBankDetails.repaymentRequests.isListFetched;
export const repaymentsOrganizationRepaymentRequestsMorePagesSelector = (state: RootState): boolean => state.repaymentsBankDetails.repaymentRequests.morePages;
export const repaymentsOrganizationRepaymentRequestsSearchStatusSelector = (state:
  RootState) => state.repaymentsBankDetails.repaymentRequestsSearch.repaymentStatus;
export const repaymentsOrganizationRepaymentRequestsSearchFromDateSelector = (state: RootState) => state.repaymentsBankDetails.repaymentRequestsSearch.fromDate;
export const repaymentsOrganizationRepaymentRequestsSearchToDateSelector = (state: RootState) => state.repaymentsBankDetails.repaymentRequestsSearch.toDate;
export const repaymentsOrganizationDestinationAccountsSelector = (
  state: RootState,
): DestinationAccountType[] => state.repaymentsBankDetails.destinationAccounts.list;
export const repaymentsOrganizationSourceAccountsIsAccountCreatedOrEditedSelector = (
  state: RootState,
): boolean => state.repaymentsBankDetails.sourceAccounts.isAccountCreatedOrEdited;
export const repaymentsOrganizationDestinationAccountsFetchFailedSelector = (
  state: RootState,
) : boolean => state.repaymentsBankDetails.destinationAccounts.fetchFailed;
export const repaymentsOrganizationSourceAccountsIsAccountDeletedSelector = (
  state: RootState,
) : boolean => state.repaymentsBankDetails.sourceAccounts.isAccountDeleted;
export const repaymentOrganizationRepaymentRequestsProcessFulfilledErrorsSelector = (state: RootState)
: string[] => state.repaymentsBankDetails.processAction.errors;
export const selectedRepaymentRequestsOptionChosenSelector = (state: RootState): boolean => state.repaymentsBankDetails.isSelectedRepaymentRequestsOptionChosen;
export const selectedRepaymentRequestsListSelector = (state: RootState)
  : RepaymentRequestType[] => state.repaymentsBankDetails.selectedRepaymentRequests.list;
export const repaymentOrganizationRepaymentRequestsProcessPendingSelector = (state: RootState)
: boolean => state.repaymentsBankDetails.processAction.pending;

export const organizationSettingsPendingSelector = (state: RootState): boolean => state.repaymentsBankDetails.organizationSettings.pending;
export const organizationSettingsDataSelector = (state: RootState) => state.repaymentsBankDetails.organizationSettings.data;
export const organizationSettingsFailedSelector = (state: RootState) => state.repaymentsBankDetails.organizationSettings.fetchFailed;

export default repaymentsBankDetailsSlice.reducer;
