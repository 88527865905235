import { Value } from 'baseui/select';

export type Contact = {
  key: number,
  contactName: string,
  contactPhone: string,
  contactAddress: string,
}

export const profileInitialValues = {
  externalId: '',
  name: '',
  deductionCode: '',
  netSuiteId: '',
  salesForceId: '',
  externalPayrollSystemId: '',
  externalIntegrationId: '',
  organizationTimezone: 'America/Chicago',
  programStatus: [{ value: 'ACTIVE' }],
  employmentDataSource: [{ value: 'ADMIN_API' }],
  tnaDataSource: [{ value: 'HOTSCHEDULES' }],
  tnaDataImport: [{ value: 'HOTSCHEDULES' }],
  deductionsCsvFormat: [{ value: 'DEFAULT' }],
  payrollPeriodStartDay: [{ value: 'TUESDAY' }],
  paydayOffset: [{ value: 5 }],
  payrollCutoffTime: new Date(new Date().setHours(11, 0, 0)),
  businessDayStartTime: new Date(new Date().setHours(9, 0, 0)),
  automaticBatchGeneration: false,
  offerCalculationMode: [{ value: 'BY_EXTERNAL_EMPLOYEE_ID' }],
  batchDeliveryManual: true,
  batchDeliveryEmail: false,
  organizationBatchDeliveryEmailAddresses: null as string | null,
  deductionShortfallSystem: [{ value: 'FUEGO' }],
  estimatedPay: true,
};

export const deductionBatchesInitialValues = {
  workerIds: [] as string[],
};

export const EWASettingsInitialValues = {
  ewaEnabled: false,
  payrollCutoffTime: new Date(new Date().setHours(11, 0, 0)),
  offerAvailableTime: new Date(new Date().setHours(10, 0, 0)),
  autoClockOutTime: new Date(new Date().setHours(5, 0, 0)),
  hourlyEmployees: false,
  salaryEmployees: false,
  excludeTippedShifts: false,
  excludeAutoClockedOutShifts: false,
  offerPercentPerEmployee: 50,
  maxShiftLength: 12,
  maxOfferTotal: 1000,
  locationsFile: File,
  payGroupName: '',
  payGroupClientID: '',
  businessDayStartTime: new Date(new Date().setHours(9, 0, 0)),
  payCycle: [{ value: 'payCycle1' }],
  payrollPeriodStartDay: [{ value: 'TUESDAY' }],
  paydayOffset: [{ value: 5 }],
  lastPayPeriodStartDate: new Date(),
  payrollDeductionCode: '',
  jobCodeExclusions: false,
  jobCodeExclusionsValue: '',
  offerProRataMethod: [{ value: 'CALENDAR_DAYS' }],
  hourlyPaidEmployeesEnable: true,
  salariedEmployeesEnable: false,
  regularlyPaidEmployeesEnable: false,
  ewaMaxHourlyRateTimecard: 30,
  ewaMaxHourlyRateSalariedEmployees: 30,
};

export const TPOSettingsInitialValues = {
  tpoEnabled: false,
  tpoPercent: 50,
  tpoDailyPayoutSchedule: [{ value: 'API' }], // API, SCHEDULED, API_OR_SCHEDULED
  tpoDailyPayoutTime: new Date(new Date().setHours(9, 0, 0)),
  tpoFeesPaidByEmployer: false,
  tpoFeeEmployeePerPeriod: false,
  tpoFeeEmployeePerPeriodValue: 0,
  tpoFuegoCardPaymentEnabled: false,
  tpoFuegoCardPaymentValue: 0,
  tpoAchPaymentFeeEnabled: false,
  tpoAchPaymentFeeValue: 0,
  tpoSameDayAchPaymentFeeEnabled: false,
  tpoSameDayAchPaymentFeeValue: 0,
  tpoPushToCardPaymentFeeEnabled: false,
  tpoPushToCardPaymentFeeValue: 0,
};

export const DirectDepositSettingsInitialValues = {
  directDepositSetting: [{ value: '' }],
  organizationName: '',
  fddTermsAndConditions: null as string | null,
  ddCustomMessage: '',
  automaticFDDReportEnabled: false,
  automaticFDDReportEmailRecipients: '',
  automaticFDDReportFrequency: [{ value: '' }],
  automaticFDDReportOccurrence: [{ value: '' }],
  fddExternalPayrollSystemId: '',
};

export const TipSourceConfigurationInitialValues = {
  ewa3rdPartyTips: false,
  ewaServiceCharges: false,
  ewaCashTips: false,
  ewaCCTips: false,
  ewaDeclaredTips: false,
  ewaTipsPool: false,
  ewaInclude3rdPartyTips: false,
  ewaIncludeServiceCharges: false,
  ewaIncludeCashTips: false,
  ewaIncludeCCTips: false,
  ewaIncludeDeclaredTips: false,
  ewaIncludeTipsPool: false,
  ewaSubtractIncluded3rdPartyTips: false,
  ewaSubtractIncludedServiceCharges: false,
  ewaSubtractIncludedCashTips: false,
  ewaSubtractIncludedCCTips: false,
  ewaSubtractIncludedDeclaredTips: false,
  ewaSubtractIncludedTipsPool: false,
  tpo3rdPartyTips: false,
  tpoServiceCharges: false,
  tpoCashTips: false,
  tpoCCTips: false,
  tpoDeclaredTips: false,
  tpoTipsPool: false,
  tpoInclude3rdPartyTips: false,
  tpoIncludeServiceCharges: false,
  tpoIncludeCashTips: false,
  tpoIncludeCCTips: false,
  tpoIncludeDeclaredTips: false,
  tpoIncludeTipsPool: false,
  tpoSubtractIncluded3rdPartyTips: false,
  tpoSubtractIncludedServiceCharges: false,
  tpoSubtractIncludedCashTips: false,
  tpoSubtractIncludedCCTips: false,
  tpoSubtractIncludedDeclaredTips: false,
  tpoSubtractIncludedTipsPool: false,
};

export const PaycardManagementInitialValues = {
  externalIntegrationId: [] as Value,
  paycardCustomer: false,
  programId: [] as Value,
  cardManagement: false,
  fundingAccountId: [] as Value,
};

export const BankAccountInitialValues = {
  organizationName: '',
  routingNumber: '',
  accountNumber: '',
  legalName: '',
  nickname: '',
  repaymentStatement: '',
};
