import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { resetNotification } from 'store/events';
import { RootState } from '..';
import {
  saveAdministrator,
  updateAdminRoles,
} from './administrators';
import {
  assignEmployeesToEmployment,
  changeWorkerPaycardOffer,
  createACF,
  createBadDebt,
  createDeductionBatches,
  editEmployeeDetailsOnDemandPay,
  editEmployeeDetailsProfile,
  saveZendeskTicket,
} from './employees';
import {
  createLocation,
  editLocation,
  editLocationConfigs,
  importLocations,
  patchLocation,
  saveLocationPaycardConfig,
} from './locations';
import {
  createOrganization,
  editOrganization,
  editOrganizationEWASettings,
  editOrganizationTipSourceConfiguration,
  saveOrganizationDirectDepositSettingsBanking,
  saveOrganizationTPOSettingsBanking,
} from './organizations';
import {
  assignLocationsToPayGroup,
  createPayGroup,
  editPayGroup,
  patchPayGroup,
  savePayGroupPayPeriods,
} from './payGroups';
import {
  createBatchAllocations,
  createDrawAllocations,
  createEmploymentAllocations,
} from './paymentAllocation';
import {
  createRepayment,
  createTPORepaymentAllocation,
  updateRepayment,
} from './repayments';
import {
  importTipsFile,
  initiateTPOPayments,
} from './tpo';
import { setWorkerProfileAttributesTrusted } from './workers';
import { recoverFunds } from './transactions';
import { setTnALocationSettings } from './tna';
import {
  loadFunds,
  registerPaycard,
  saveOrganizationPaycardSettings,
} from './paycards';
import {
  deleteSourceAccount,
  editRepayment,
  editSourceAccount,
  saveOrganizationSettings,
  saveSourceAccount,
} from './repaymentsBankDetails';

export enum NotificationType {
  INFO = 'INFO',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface AppNotification {
  isOpen: boolean
  type?: NotificationType
  title?: string
  titleKey?: string
  text?: string
  textKey?: string
  autoHideDuration?: number
}

export const initialState: AppNotification[] = [{
  isOpen: false,
  type: NotificationType.SUCCESS,
  title: undefined,
  titleKey: 'successfullySaved.title',
  text: undefined,
  textKey: undefined,
  autoHideDuration: 0,
}];

export const handleNotification = (state: AppNotification[], notification?: PayloadAction<any>) => {
  const { payload } = notification || {};
  const {
    isOpen = true,
    type = initialState[0].type,
    title = initialState[0].title,
    titleKey = initialState[0].titleKey,
    text = initialState[0].text,
    textKey = initialState[0].textKey,
    autoHideDuration = 3000,
  } = payload || {};

  const existingNotificationIndex = state.findIndex(
    (notif) => notif.titleKey === titleKey && notif.type === type,
  );

  if (existingNotificationIndex !== -1) {
    state[existingNotificationIndex] = {
      ...state[existingNotificationIndex],
      isOpen,
      type,
      title,
      titleKey,
      text,
      textKey,
      autoHideDuration,
    };
  } else {
    state.push({
      isOpen,
      type,
      title,
      titleKey,
      text,
      textKey,
      autoHideDuration,
    });
  }
};

const successfullyEditedPayloadAction = {
  type: NotificationType.SUCCESS,
  payload: {
    titleKey: 'successfullyEdited.title',
  },
};
const successfullySavedPayloadAction = {
  type: NotificationType.SUCCESS,
  payload: {
    titleKey: 'successfullySaved.title',
  },
};

const successfullyCreatedBankAccountAction = {
  type: NotificationType.SUCCESS,
  payload: {
    titleKey: 'successfullySaved.title',
  },
};

const successfullyDeletedBankAccountAction = {
  type: NotificationType.SUCCESS,
  payload: {
    titleKey: 'successfullyDeleted.title',
  },
};

const successfullyAllocatedTPOPaymentAction = {
  type: NotificationType.SUCCESS,
  payload: {
    titleKey: 'successTPOPaymentAllocation.title',
  },
};

const successfullyInitiatedTPOPaymentAction = {
  type: NotificationType.SUCCESS,
  payload: {
    titleKey: 'successfullyInitiated.title',
    textKey: 'successTPOPaymentInitiated.text',
    autoHideDuration: 15000,
  },
};

const successfullyUploadedFileAction = {
  type: NotificationType.SUCCESS,
  payload: {
    titleKey: 'successfullyUploaded.title',
  },
};

const successfullyUpdatedAction = {
  type: NotificationType.SUCCESS,
  payload: {
    titleKey: 'successfullyUpdated.title',
  },
};

const successfullyTriggeredTrustedUserAction = {
  type: NotificationType.SUCCESS,
  payload: {
    titleKey: 'successfullyTriggeredAction.title',
  },
};

const successfullyRecoverFundsAction = {
  type: NotificationType.SUCCESS,
  payload: {
    titleKey: 'successRecoverFunds.title',
  },
};

const successfullySetTnAAutomaticSynchingAction = {
  type: NotificationType.SUCCESS,
  payload: {
    titleKey: 'successfullySetTnAAutomaticSynching.title',
  },
};

const successfullyRegisteredPaycardAction = {
  type: NotificationType.SUCCESS,
  payload: {
    titleKey: 'successfullyRegisteredPaycard.title',
  },
};

const successfullyLoadFundsAction = {
  type: NotificationType.SUCCESS,
  payload: {
    titleKey: 'successfullyLoadedFunds.title',
  },
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state: AppNotification[], { payload }: PayloadAction<AppNotification>) => {
      const existingNotificationIndex = state.findIndex(
        (notif) => notif.titleKey === payload.titleKey && notif.type === payload.type,
      );
      if (existingNotificationIndex !== -1) {
        state[existingNotificationIndex] = { ...state[existingNotificationIndex], ...payload, isOpen: true };
      } else {
        state.push(payload);
      }
    },
    removeNotification: (state: AppNotification[], { payload }: PayloadAction<number>) => state.filter((_, index) => index !== payload)
    ,
  },
  extraReducers: (builder) => {
    builder.addCase(resetNotification, () => initialState);
    builder.addCase(assignLocationsToPayGroup.fulfilled, handleNotification);
    builder.addCase(assignEmployeesToEmployment.fulfilled, handleNotification);
    builder.addCase(saveZendeskTicket.fulfilled, handleNotification);
    builder.addCase(savePayGroupPayPeriods.fulfilled, handleNotification);
    builder.addCase(createOrganization.fulfilled, (state) => handleNotification(state, successfullySavedPayloadAction));
    builder.addCase(saveAdministrator.fulfilled, (state) => handleNotification(state, successfullySavedPayloadAction));
    builder.addCase(createDeductionBatches.fulfilled, (state) => handleNotification(state, successfullySavedPayloadAction));
    builder.addCase(createLocation.fulfilled, handleNotification);
    builder.addCase(importLocations.fulfilled, handleNotification);
    builder.addCase(createRepayment.fulfilled, (state) => handleNotification(state, successfullySavedPayloadAction));
    builder.addCase(createPayGroup.fulfilled, handleNotification);
    builder.addCase(createDrawAllocations.fulfilled, handleNotification);
    builder.addCase(createBatchAllocations.fulfilled, handleNotification);
    builder.addCase(createEmploymentAllocations.fulfilled, handleNotification);
    builder.addCase(editEmployeeDetailsOnDemandPay.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(editEmployeeDetailsProfile.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(changeWorkerPaycardOffer.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(editLocation.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(editLocationConfigs.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(editOrganization.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(editOrganizationEWASettings.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(editOrganizationTipSourceConfiguration.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(editPayGroup.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(createACF.fulfilled, (state) => handleNotification(state, successfullySavedPayloadAction));
    builder.addCase(createBadDebt.fulfilled, (state) => handleNotification(state, successfullySavedPayloadAction));
    builder.addCase(saveOrganizationTPOSettingsBanking.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(updateRepayment.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(createTPORepaymentAllocation.fulfilled, (state) => handleNotification(state, successfullyAllocatedTPOPaymentAction));
    builder.addCase(initiateTPOPayments.fulfilled, (state) => handleNotification(state, successfullyInitiatedTPOPaymentAction));
    builder.addCase(importTipsFile.fulfilled, (state) => handleNotification(state, successfullyUploadedFileAction));
    builder.addCase(updateAdminRoles.fulfilled, (state) => handleNotification(state, successfullyUpdatedAction));
    builder.addCase(saveOrganizationDirectDepositSettingsBanking.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(setWorkerProfileAttributesTrusted.fulfilled, (state) => handleNotification(state, successfullyTriggeredTrustedUserAction));
    builder.addCase(recoverFunds.fulfilled, (state) => handleNotification(state, successfullyRecoverFundsAction));
    builder.addCase(setTnALocationSettings.fulfilled, (state) => handleNotification(state, successfullySetTnAAutomaticSynchingAction));
    builder.addCase(saveOrganizationPaycardSettings.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(saveSourceAccount.fulfilled, (state) => handleNotification(state, successfullyCreatedBankAccountAction));
    builder.addCase(editSourceAccount.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(deleteSourceAccount.fulfilled, (state) => handleNotification(state, successfullyDeletedBankAccountAction));
    builder.addCase(patchLocation.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(patchPayGroup.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(saveLocationPaycardConfig.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(registerPaycard.fulfilled, (state) => handleNotification(state, successfullyRegisteredPaycardAction));
    builder.addCase(loadFunds.fulfilled, (state) => handleNotification(state, successfullyLoadFundsAction));
    builder.addCase(editRepayment.fulfilled, (state) => handleNotification(state, successfullyEditedPayloadAction));
    builder.addCase(saveOrganizationSettings.fulfilled, (state) => handleNotification(state, successfullyCreatedBankAccountAction));
  },
});

export const {
  setNotification,
  removeNotification,
} = notificationSlice.actions;
export default notificationSlice.reducer;

export const notificationSelector = (state: RootState): AppNotification[] => state.notification;
