import { ReactNode } from 'react';
import { payrollInitialValues } from 'initialValues/PayGroupsInitialValues';
import {
  profileInitialValues,
  EWASettingsInitialValues,
  deductionBatchesInitialValues,
  TPOSettingsInitialValues,
  DirectDepositSettingsInitialValues,
  TipSourceConfigurationInitialValues,
  PaycardManagementInitialValues,
  BankAccountInitialValues,
} from 'initialValues/OrganizationInitialValues';
import { Organization } from 'store/slices/organizations';
import { DrawRequestFilterType, EmployeeIDType } from './EmployeeTypes';
import { PayrollPeriodIDType } from './PayGroupTypes';
import { MoneyDtoType } from './CommonTypes';
import { WorkerIDType } from './WorkerTypes';

export type ProfileValuesType = typeof profileInitialValues;
export type DeductionBatchesValuesType = typeof deductionBatchesInitialValues;
export type PayrollValuesType = typeof payrollInitialValues;
export type EWASettingsValuesType = typeof EWASettingsInitialValues;
export type TPOSettingsValuesType = typeof TPOSettingsInitialValues;
export type PaycardManagementValuesType = typeof PaycardManagementInitialValues;
export type DirectDepositSettingsValuesType = typeof DirectDepositSettingsInitialValues;
export type TipSourceConfigurationValuesType = typeof TipSourceConfigurationInitialValues;

export interface IItem {
  label: string,
  value: string
}

export type OrganizationMenuItem = {
  label: string,
  value: string,
  path?: string,
}

export type OrganizationFormSectionPropsType = {
  children?: ReactNode,
  title?: string,
  status?: {
    sectionID: number | undefined,
    name: OrganizationSectionProgressStatusType | undefined,
    sectionName?: OrganizationSectionNameType
  },
  disabled?: boolean,
  disabledStatus?: boolean,
  showStatus?: boolean,
  expanded?: boolean,
  saveStatus?: Function,
  onExpand?: Function,
}

export type OrganizationIDType = string | null | undefined;

export type OrganizationSectionIDType = number | null | undefined;

export enum ConfigType {
  EWA_ENABLED = 'EARNED_WAGE_ACCESS_ENABLED',
  INCLUDE_TIPPED_SHIFTS = 'INCLUDE_TIPPED_SHIFTS',
  INCLUDE_CC_TIPS = 'INCLUDE_CC_TIPS',
  INCLUDE_3RD_PARTY_TIPS = 'INCLUDE_3RD_PARTY_TIPS',
  INCLUDE_SERVICE_CHARGES = 'INCLUDE_SERVICE_CHARGES',
  INCLUDE_DECLARED_TIPS = 'INCLUDE_DECLARED_TIPS',
  INCLUDE_CASH_TIPS = 'INCLUDE_CASH_TIPS',
  INCLUDE_TIPS_POOL = 'INCLUDE_TIPS_POOL',
  SUBTRACT_INCLUDED_CC_TIPS = 'SUBTRACT_INCLUDED_CC_TIPS',
  SUBTRACT_INCLUDED_SERVICE_CHARGES = 'SUBTRACT_INCLUDED_SERVICE_CHARGES',
  SUBTRACT_INCLUDED_3RD_PARTY_TIPS = 'SUBTRACT_INCLUDED_3RD_PARTY_TIPS',
  SUBTRACT_INCLUDED_DECLARED_TIPS = 'SUBTRACT_INCLUDED_DECLARED_TIPS',
  SUBTRACT_INCLUDED_CASH_TIPS = 'SUBTRACT_INCLUDED_CASH_TIPS',
  SUBTRACT_INCLUDED_TIPS_POOL = 'SUBTRACT_INCLUDED_TIPS_POOL',
  OFFER_PERCENT = 'OFFER_PERCENT',
  OFFER_AVAILABLE_TIME = 'OFFER_AVAILABLE_TIME',
  MAX_SHIFT_LENGTH = 'MAX_SHIFT_LENGTH',
  MAX_OFFER_TOTAL = 'MAX_OFFER_TOTAL',
  AUTO_CLOCK_OUT_TIME = 'AUTO_CLOCK_OUT_TIME',
  INCLUDE_AUTO_CLOCKED_OUT_SHIFTS = 'INCLUDE_AUTO_CLOCKED_OUT_SHIFTS',
  DAILY_DRAW_FEE = 'DAILY_DRAW_FEE',
  FUEGO_CARD_PAYMENT_FEE = 'FUEGO_CARD_PAYMENT_FEE',
  ACH_PAYMENT_FEE = 'ACH_PAYMENT_FEE',
  SAME_DAY_ACH_PAYMENT_FEE = 'SAME_DAY_ACH_PAYMENT_FEE',
  PUSH_TO_CARD_PAYMENT_FEE = 'PUSH_TO_CARD_PAYMENT_FEE',
  FEE_CAP = 'FEE_CAP',
  DAILY_DRAW_FEE_LIMIT = 'DAILY_DRAW_FEE_LIMIT',
  WEEKLY_DRAW_FEE_LIMIT = 'WEEKLY_DRAW_FEE_LIMIT',
  BI_WEEKLY_DRAW_FEE_LIMIT = 'BI_WEEKLY_DRAW_FEE_LIMIT',
  EMPLOYER_FEE_LIMIT = 'EMPLOYER_FEE_LIMIT',
  CURRENCY = 'CURRENCY',
  SCALE = 'SCALE',
  PROGRAM_STATUS = 'PROGRAM_STATUS',
  BUSINESS_DAY_START_TIME = 'BUSINESS_DAY_START_TIME',
  PAYROLL_PERIOD_START_DAY = 'PAYROLL_PERIOD_START_DAY',
  PAYDAY_OFFSET = 'PAYDAY_OFFSET',
  PAYROLL_CUTOFF_TIME = 'PAYROLL_CUTOFF_TIME',
  CORPORATE_TIMEZONE = 'CORPORATE_TIMEZONE',
  DRAW_MINIMUM_AMOUNT = 'DRAW_MINIMUM_AMOUNT',
  JOB_CODE_EXCLUSIONS = 'JOB_CODE_EXCLUSIONS',
  DEDUCTIONS_CSV_FORMAT = 'DEDUCTIONS_CSV_FORMAT',
  EMPLOYMENTS_SOURCE_IMPORT = 'EMPLOYMENTS_SOURCE_IMPORT',
  TNA_SOURCE_INTEGRATION = 'TNA_SOURCE_INTEGRATION',
  TNA_SOURCE_IMPORT = 'TNA_SOURCE_IMPORT',
  AUTOMATIC_BATCH_GENERATION = 'AUTOMATIC_BATCH_GENERATION',
  OFFER_CALCULATION_MODE = 'OFFER_CALCULATION_MODE',
  BATCH_DELIVERY_METHODS = 'BATCH_DELIVERY_METHODS',
  BATCH_DELIVERY_EMAIL_ADDRESSES = 'BATCH_DELIVERY_EMAIL_ADDRESSES',
  TPO_ENABLED = 'TIP_CASH_OUT_ENABLED',
  TPO_PERCENT = 'TIP_CASH_OUT_PERCENT',
  TPO_DAILY_PAYOUT_SCHEDULE = 'TIP_CASH_OUT_DAILY_PAYOUT_MODE',
  TPO_DAILY_PAYOUT_TIME = 'TIP_CASH_OUT_DAILY_PAYOUT_TIME',
  TPO_INCLUDE_CC_TIPS = 'TIP_CASH_OUT_INCLUDE_CC_TIPS',
  TPO_INCLUDE_DECLARED_TIPS = 'TIP_CASH_OUT_INCLUDE_DECLARED_TIPS',
  TPO_INCLUDE_CASH_TIPS = 'TIP_CASH_OUT_INCLUDE_CASH_TIPS',
  TPO_INCLUDE_TIPS_POOL = 'TIP_CASH_OUT_INCLUDE_TIPS_POOL',
  TPO_INCLUDE_SERVICE_CHARGES = 'TIP_CASH_OUT_INCLUDE_SERVICE_CHARGES',
  TPO_INCLUDE_3RD_PARTY_TIPS = 'TIP_CASH_OUT_INCLUDE_3RD_PARTY_TIPS',
  TPO_SUBTRACT_INCLUDED_CC_TIPS = 'TIP_CASH_OUT_SUBTRACT_INCLUDED_CC_TIPS',
  TPO_SUBTRACT_INCLUDED_DECLARED_TIPS = 'TIP_CASH_OUT_SUBTRACT_INCLUDED_DECLARED_TIPS',
  TPO_SUBTRACT_INCLUDED_CASH_TIPS = 'TIP_CASH_OUT_SUBTRACT_INCLUDED_CASH_TIPS',
  TPO_SUBTRACT_INCLUDED_TIPS_POOL = 'TIP_CASH_OUT_SUBTRACT_INCLUDED_TIPS_POOL',
  TPO_SUBTRACT_INCLUDED_SERVICE_CHARGES = 'TIP_CASH_OUT_SUBTRACT_INCLUDED_SERVICE_CHARGES',
  TPO_SUBTRACT_INCLUDED_3RD_PARTY_TIPS = 'TIP_CASH_OUT_SUBTRACT_INCLUDED_3RD_PARTY_TIPS',
  TPO_FEES_PAID_BY_EMPLOYER = 'TIP_CASH_OUT_FEES_PAID_BY_EMPLOYER',
  TPO_FEE_EMPLOYEE_PER_PERIOD_VALUE = 'TIP_CASH_OUT_EMPLOYEE_PER_PERIOD_FEE',
  TPO_FUEGO_CARD_PAYMENT_ENABLED = 'TIP_CASH_OUT_FUEGO_CARD_PAYMENT_ENABLED',
  TPO_FUEGO_CARD_PAYMENT_VALUE = 'TIP_CASH_OUT_FUEGO_CARD_PAYMENT_FEE',
  TPO_ACH_PAYMENT_ENABLED = 'TIP_CASH_OUT_ACH_PAYMENT_ENABLED',
  TPO_ACH_PAYMENT_FEE_VALUE = 'TIP_CASH_OUT_ACH_PAYMENT_FEE',
  TPO_SAME_DAY_ACH_PAYMENT_ENABLED = 'TIP_CASH_OUT_SAME_DAY_ACH_PAYMENT_ENABLED',
  TPO_SAME_DAY_ACH_PAYMENT_FEE_VALUE = 'TIP_CASH_OUT_SAME_DAY_ACH_PAYMENT_FEE',
  TPO_PUSH_TO_CARD_PAYMENT_ENABLED = 'TIP_CASH_OUT_PUSH_TO_CARD_PAYMENT_ENABLED',
  TPO_PUSH_TO_CARD_PAYMENT_FEE_VALUE = 'TIP_CASH_OUT_PUSH_TO_CARD_PAYMENT_FEE',
  DEDUCTION_SHORTFALL_SYSTEM = 'DEDUCTION_SHORTFALL_SYSTEM',
  BANK_DETAILS_ACCOUNT_NAME = 'BANK_DETAILS_ACCOUNT_NAME',
  BANK_DETAILS_ACCOUNT_NUMBER = 'BANK_DETAILS_ACCOUNT_NUMBER',
  BANK_DETAILS_ROUTING_NUMBER = 'BANK_DETAILS_ROUTING_NUMBER',
  BANK_DETAILS_REFERENCE = 'BANK_DETAILS_REFERENCE',
  ESTIMATED_PAY_ENABLED = 'ESTIMATED_PAY_ENABLED',
  DIRECT_DEPOSIT = 'DIRECT_DEPOSIT',
  SUPPORTED_EMPLOYMENT_EWA_TYPES = 'SUPPORTED_EMPLOYMENT_EWA_TYPES',
  OFFER_PRO_RATA_METHOD = 'OFFER_PRO_RATA_METHOD',
  EMPLOYEE_SCHEDULING_SUPPORTED = 'EMPLOYEE_SCHEDULING_SUPPORTED',
  EWA_MAX_HOURLY_RATE_TIMECARD = 'EWA_MAX_HOURLY_RATE_TIMECARD',
  EWA_MAX_HOURLY_RATE_SALARIED_EMPLOYEE = 'EWA_MAX_HOURLY_RATE_SALARIED_EMPLOYEE',
  TPO_MAX_EARNINGS_PER_BUSINESS_DAY = 'TPO_MAX_EARNINGS_PER_BUSINESS_DAY',
  TPO_MAX_EARNINGS_PER_PERIOD = 'TPO_MAX_EARNINGS_PER_PERIOD',
}

export enum BatchDeliveryMethods {
  API_MANUAL = 'API_MANUAL',
  EMAIL = 'EMAIL'
}

export type DefaultConfigResponseType = {
  ACH_PAYMENT_FEE: number,
  AUTO_CLOCK_OUT_TIME: string,
  BI_WEEKLY_DRAW_FEE_LIMIT: number,
  BUSINESS_DAY_START_TIME: string,
  CORPORATE_TIMEZONE: string,
  CURRENCY: string,
  DAILY_DRAW_FEE: number,
  DAILY_DRAW_FEE_LIMIT: number,
  EMPLOYER_FEE_LIMIT: number,
  FEE_CAP: number,
  FUEGO_CARD_PAYMENT_FEE: number,
  EWA_ENABLED: boolean,
  INCLUDE_AUTO_CLOCKED_OUT_SHIFTS: boolean,
  INCLUDE_CC_TIPS: boolean,
  INCLUDE_3RD_PARTY_TIPS: boolean,
  INCLUDE_SERVICE_CHARGES: boolean,
  INCLUDE_TIPPED_SHIFTS: boolean,
  INCLUDE_DECLARED_TIPS: boolean,
  INCLUDE_CASH_TIPS: boolean,
  INCLUDE_TIPS_POOL: boolean,
  SUBTRACT_INCLUDED_CC_TIPS: boolean,
  SUBTRACT_INCLUDED_SERVICE_CHARGES: boolean,
  SUBTRACT_INCLUDED_3RD_PARTY_TIPS: boolean,
  SUBTRACT_INCLUDED_DECLARED_TIPS: boolean,
  SUBTRACT_INCLUDED_CASH_TIPS: boolean,
  SUBTRACT_INCLUDED_TIPS_POOL: boolean,
  MAX_OFFER_TOTAL: number,
  MAX_SHIFT_LENGTH: number,
  OFFER_AVAILABLE_TIME: string,
  OFFER_PERCENT: number,
  PAYDAY_OFFSET: number,
  PAYROLL_CUTOFF_TIME: string,
  PAYROLL_PERIOD_START_DAY: string,
  PROGRAM_STATUS: string,
  PUSH_TO_CARD_PAYMENT_FEE: number,
  SAME_DAY_ACH_PAYMENT_FEE: number,
  SCALE: number,
  WEEKLY_DRAW_FEE_LIMIT: number,
  JOB_CODE_EXCLUSIONS: string,
  DEDUCTIONS_CSV_FORMAT: string,
  EMPLOYMENTS_SOURCE_IMPORT: string,
  TNA_SOURCE_INTEGRATION: string,
  TNA_SOURCE_IMPORT: string,
  AUTOMATIC_BATCH_GENERATION: boolean,
  OFFER_CALCULATION_MODE: string,
  BATCH_DELIVERY_METHODS: string,
  BATCH_DELIVERY_EMAIL_ADDRESSES: string,
  TPO_ENABLED: boolean,
  TPO_PERCENT: number,
  TPO_DAILY_PAYOUT_SCHEDULE: string,
  TPO_DAILY_PAYOUT_TIME: string | null,
  TPO_INCLUDE_CC_TIPS: boolean,
  TPO_INCLUDE_SERVICE_CHARGES: boolean,
  TPO_INCLUDE_3RD_PARTY_TIPS: boolean,
  TPO_INCLUDE_DECLARED_TIPS: boolean,
  TPO_INCLUDE_CASH_TIPS: boolean,
  TPO_INCLUDE_TIPS_POOL: boolean,
  TPO_SUBTRACT_INCLUDED_CC_TIPS: boolean,
  TPO_SUBTRACT_INCLUDED_SERVICE_CHARGES: boolean,
  TPO_SUBTRACT_INCLUDED_3RD_PARTY_TIPS: boolean,
  TPO_SUBTRACT_INCLUDED_DECLARED_TIPS: boolean,
  TPO_SUBTRACT_INCLUDED_CASH_TIPS: boolean,
  TPO_SUBTRACT_INCLUDED_TIPS_POOL: boolean,
  TPO_FEES_PAID_BY_EMPLOYER: boolean,
  TPO_FEE_EMPLOYEE_PER_PERIOD_VALUE: number | null,
  TPO_FUEGO_CARD_PAYMENT_ENABLED: boolean,
  TPO_FUEGO_CARD_PAYMENT_VALUE: number | null,
  TPO_ACH_PAYMENT_ENABLED: boolean,
  TPO_ACH_PAYMENT_FEE_VALUE: number | null,
  TPO_SAME_DAY_ACH_PAYMENT_ENABLED: boolean,
  TPO_SAME_DAY_ACH_PAYMENT_FEE_VALUE: number | null,
  TPO_PUSH_TO_CARD_PAYMENT_ENABLED: boolean,
  TPO_PUSH_TO_CARD_PAYMENT_FEE_VALUE: number | null,
  DEDUCTION_SHORTFALL_SYSTEM: string,
  BANK_DETAILS_ACCOUNT_NAME: string | null,
  BANK_DETAILS_ACCOUNT_NUMBER: number | null,
  BANK_DETAILS_ROUTING_NUMBER: number | null,
  BANK_DETAILS_REFERENCE: string | null,
  ESTIMATED_PAY_ENABLED: boolean,
  DIRECT_DEPOSIT: string,
  SUPPORTED_EMPLOYMENT_EWA_TYPES: string,
  OFFER_PRO_RATA_METHOD: string,
  EWA_MAX_HOURLY_RATE_TIMECARD: number | null,
  EWA_MAX_HOURLY_RATE_SALARIED_EMPLOYEE: number | null,
  TPO_MAX_EARNINGS_PER_BUSINESS_DAY: number | null,
  TPO_MAX_EARNINGS_PER_PERIOD: number | null,
}

export type DefaultConfigFormType = {
  autoClockOutTime?: Date
  excludeAutoClockedOutShifts?: boolean
  ewaIncludeCCTips?: boolean
  ewaInclude3rdPartyTips?: boolean
  ewaIncludeServiceCharges?: boolean
  ewaIncludeDeclaredTips?: boolean
  ewaIncludeCashTips?: boolean
  ewaIncludeTipsPool?: boolean
  ewaSubtractIncludedCCTips?: boolean
  ewaSubtractIncluded3rdPartyTips?: boolean
  ewaSubtractIncludedServiceCharges?: boolean
  ewaSubtractIncludedDeclaredTips?: boolean
  ewaSubtractIncludedCashTips?: boolean
  ewaSubtractIncludedTipsPool?: boolean
  excludeTippedShifts?: boolean
  maxOfferTotal?: number
  maxShiftLength?: number
  offerAvailableTime?: Date
  offerPercentPerEmployee?: number
  payrollPeriodStartDay?: [{ value: string }]
  automaticBatchGeneration?: boolean
  tpoOfferPercentPerEmployee?: number
  tpoIncludeCCTips?: boolean
  tpoInclude3rdPartyTips?: boolean
  tpoIncludeServiceCharges?: boolean
  tpoIncludeDeclaredTips?: boolean
  tpoIncludeCashTips?: boolean
  tpoIncludeTipsPool: boolean
  tpoSubtractIncludedCCTips?: boolean
  tpoSubtractIncluded3rdPartyTips?: boolean
  tpoSubtractIncludedServiceCharges?: boolean
  tpoSubtractIncludedDeclaredTips?: boolean
  tpoSubtractIncludedCashTips?: boolean
  tpoSubtractIncludedTipsPool: boolean
  tpoEnabled: boolean,
  ewaEnabled: boolean,
  ewaMaxHourlyRateTimecard?: number,
  ewaMaxHourlyRateSalariedEmployees?: number
  tpoMaxEarningsPerBusinessDay?: number,
  tpoMaxEarningsPerPeriod?: number
}

export type OrganizationConfigResponseType = {
  id?: number,
  configKeyName: ConfigType,
  configValue: string | null,
}

export enum OrganizationSectionProgressStatusType {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export enum OrganizationSectionNameType {
  PROFILE = 'PROFILE',
  PAY_GROUP = 'PAY_GROUP',
  PAY_PERIODS = 'PAY_PERIODS',
  ORG_DEFAULT_SETTINGS = 'ORG_DEFAULT_SETTINGS',
  TIP_CASH_OUT = 'TIP_CASH_OUT',
  BANK_DETAILS = 'BANK_DETAILS',
  DIRECT_DEPOSIT = 'DIRECT_DEPOSIT',
  PAYCARD_MANAGEMENT = 'PAYCARD_MANAGEMENT'
}

export type OrganizationSectionStatusesResponseType = {
  id: number
  sectionName: OrganizationSectionNameType
  status: OrganizationSectionProgressStatusType
}

export type OrganizationResponseType = {
  id: number,
  externalId: string,
  name: string,
  type: string,
  tnaPollingCron: string,
  employerLoginUrl: string,
  deductionCode: string,
  netSuiteId: string,
  salesForceId: string,
  externalIntegrationId: string,
  externalPayrollSystemId: string,
  invitationCode: string
}

export type SaveOrganizationParamsType = {
  organizationID?: OrganizationIDType
  data: SaveOrganizationRequestType
  configs: SaveOrganizationConfigsRequestType
}

export type EditOrganizationEWASettingsParamsType = {
  organizationID: OrganizationIDType
  configs: SaveOrganizationConfigsRequestType
}

export type EditOrganizationTPOSettingsParamsType = {
  organizationID: OrganizationIDType
  configs: SaveOrganizationConfigsRequestType
}

export type EditOrganizationDirectDepositSettingsParamsType = {
  organizationID: OrganizationIDType
  configs: SaveOrganizationConfigsRequestType
}

export type EditOrganizationTipSourceConfigurationParamsType = {
  organizationID: OrganizationIDType
  configs: SaveOrganizationConfigsRequestType
}

export type CreateOrganizationSectionStatusesRequestType = {
  sectionName: OrganizationSectionNameType
  status: OrganizationSectionProgressStatusType
}

export type CreateOrganizationSectionStatusesParamsType = {
  organizationID: OrganizationIDType
  data: CreateOrganizationSectionStatusesRequestType
}

export type EditOrganizationSectionStatusesParamsType = {
  organizationID: OrganizationIDType
  sectionID: OrganizationSectionIDType
  data: EditOrganizationSectionStatusesRequestType
}

export enum DrawFeeType {
  DRAW_FEE_WORKER = 'DRAW_FEE_WORKER',
  DRAW_FEE_EMPLOYER = 'DRAW_FEE_EMPLOYER',
  SAME_DAY_ACH_TRANSFER_FEE = 'SAME_DAY_ACH_TRANSFER_FEE',
  PUSH_TO_CARD_TRANSFER_FEE = 'PUSH_TO_CARD_TRANSFER_FEE',
}

export type EditEmployeeDetailsRequestType = {
  id: number,
  workerId: string,
  organization: {
    id: OrganizationIDType
    name: string
  },
  externalUserId: string,
  externalEmployeeId: string,
  status: string | null | undefined,
  statusEffectiveDate: string,
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  payrollId: string,
  organizationName: string,
  lowerEarningsThreshold: MoneyDtoType,
  ewaTypeOverridden?: string | null | undefined
}

export type EditEmployeeDetailsProfileRequestType = {
  id: number,
  workerId: string,
  organization: {
    id: OrganizationIDType
    name: string
  },
  externalUserId: string,
  externalEmployeeId: string,
  status: string | null | undefined,
  statusEffectiveDate: string,
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  payrollId: string,
  organizationName: string,
}

export type FetchByEmploymentPayrollPeriodParamsType = {
  payrollPeriodID?: PayrollPeriodIDType,
  pageNumber?: string,
  pageSize?: string
}

export type GetEmployeeDetailsParamsType = {
  organizationId: OrganizationIDType,
  employeeID: EmployeeIDType,
};

export type GetEmployeeConfigsParamsType = {
  organizationID: OrganizationIDType,
  employeeID: EmployeeIDType,
};

export type GetEmployeePayRatesParamsType = {
  organizationID: OrganizationIDType,
  employeeID: EmployeeIDType,
};

export type FetchEmployeeDetailsDrawsParamsType = {
  employeeID: EmployeeIDType,
  filter: DrawRequestFilterType,
};

export type FetchEmployeePaycardOfferParamsType = {
  organizationID: OrganizationIDType,
  workerID: WorkerIDType,
};

export type ChangeEmployeePaycardOfferParamsType = {
  organizationID: OrganizationIDType;
  workerID: WorkerIDType;
  allowed: boolean;
};

export type CreateBatchesPropsType = {
  organizationID: string | undefined,
  payrollPeriodID: number,
  data: DeductionBatchesValuesType
}

export type OrganizationListItemPropsType = {
  organization: Organization;
}

export type OrganizationFormPropsType = {
  organizationID: OrganizationIDType;
}

export type SaveOrganizationRequestType = {
  name: string,
  type: string,
  id?: OrganizationIDType,
  externalId?: string | null,
  tnaPollingCron?: string | null,
  employerLoginUrl?: string | null,
  salesForceId?: string | null,
  netSuiteId?: string | null,
  deductionCode?: string | null,
  externalIntegrationId?: string | null,
  externalPayrollSystemId?: string | null,
}

export type EditOrganizationSectionStatusesRequestType = {
  status: OrganizationSectionProgressStatusType
}

export type FetchOrganizationSectionsStatusesParamsType = {
  organizationID: OrganizationIDType,
}

export type FetchAllOrganizationsParamsType = {
  status?: OrganizationStatuses
  pageNumber?: string
  pageSize?: string
  namePattern?: string
}

export enum OrganizationStatuses {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  TERMINATED = 'TERMINATED',
  PENDING = 'PENDING',
}

export type OrganizationDirectDepositBankingConfigResponseType = {
  organizationName: string,
  serviceType: string,
  customMessage: string,
  termsAndConditions: string,
  externalPayrollSystemId: string,
}

export type SaveOrganizationConfigsRequestType = OrganizationConfigResponseType[]

export type LocationRowAssignLocationsType = {
  id: string,
  selected: boolean,
  name: string,
  organization?: string,
  externalLocationId?: string,
  posId?: string,
  timezone?: string,
  businessDayStartTime?: string,
  payGroup?: string,
  status?: string,
}

export type BankAccountValuesType = typeof BankAccountInitialValues;

export type BankAccountValuesParamsType = {
  organizationName: string,
  routingNumber: string,
  accountNumber: string,
  legalName: string,
  nickname: string,
  repaymentStatement: string,
};
