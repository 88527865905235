import {
  createContext,
  memo,
  useEffect,
} from 'react';
import {
  FormikHandlers,
  FormikHelpers,
  FormikState,
  useFormik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, ALIGNMENT } from 'baseui/layout-grid';
import { Layer } from 'baseui/layer';
import {
  BankAccountValidationSchema as validationSchema,
} from 'validation/addOrganizationSchema';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import {
  BankAccountInitialValues as bankAccountInitialValues,
} from 'initialValues/OrganizationInitialValues';
import { errorSelector } from 'store/slices/error';
import AppModal from 'components/AppModal/AppModal';
import AppInput from 'components/Form/AppInput';
import Loader from 'components/Loader';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import useIsFormChanged from 'hooks/useIsFormChanged';
import { BankAccountValuesType } from 'types/OrganizationTypes';
import {
  editSourceAccount,
  fetchRepaymentsSourceAccounts,
  repaymentsOrganizationSourceAccountsIsAccountCreatedOrEditedSelector,
  saveSourceAccount,
} from 'store/slices/repaymentsBankDetails';
import { organizationSelector } from 'store/slices/organizations';
import { saveOrganizationBankAccountMapper } from 'dataMappers/organizationsDataMappers';
import { SourceAccountType } from 'types/RepaymentsBankDetails';

export const BankAccountFormContext = createContext(
  {} as FormikState<BankAccountValuesType> & FormikHelpers<BankAccountValuesType> & FormikHandlers,
);

interface Props {
  selectedAccount: SourceAccountType | null;
}

/**
 * Organization Form Bank Account Modal component for create and edit
 *
 * @param selectedAccount selected account for edit
 */
const OrganizationFormBankAccountModal = ({ selectedAccount }: Props) => {
  const dispatch = useAppDispatch();
  const modals = useAppSelector(modalsSelector);
  const { t } = useTranslation(['organizations', 'errors', 'common']);
  const modalName = selectedAccount
    ? ModalNames.BANK_ACCOUNT_EDIT_MODAL
    : ModalNames.BANK_ACCOUNT_MODAL;
  const modalTitle = selectedAccount
    ? t('organizations:bankDetails.bankAccounts.headerEdit')
    : t('organizations:bankDetails.bankAccounts.headerAdd');
  const isModalOpen = checkIsModalOpen(modals, modalName);
  const error = useAppSelector(errorSelector);
  const organization = useAppSelector(organizationSelector);
  const { id: organizationID, name: organizationName } = organization || {};
  const isAccountCreatedOrEdited = useAppSelector(repaymentsOrganizationSourceAccountsIsAccountCreatedOrEditedSelector);

  const initialValues = selectedAccount
    ? {
      organizationName: organizationName || bankAccountInitialValues.organizationName,
      routingNumber: selectedAccount.routingNumber,
      accountNumber: selectedAccount.accountNumber,
      legalName: selectedAccount.legalName,
      nickname: selectedAccount.nickname,
      repaymentStatement: selectedAccount.repaymentStatement,
    } : {
      ...bankAccountInitialValues,
      organizationName: organizationName || bankAccountInitialValues.organizationName,
    };

  const setIsModalOpen = (
    isOpen: boolean,
  ) => {
    dispatch(setModal({
      name: modalName,
      isOpen,
    }));
  };

  const onSubmit = (
    values: BankAccountValuesType,
  ) => {
    if (selectedAccount) {
      dispatch(editSourceAccount({
        organizationID,
        accountID: selectedAccount.accountId,
        data: saveOrganizationBankAccountMapper(values),
      }));
    } else {
      dispatch(saveSourceAccount({
        organizationID,
        data: saveOrganizationBankAccountMapper(values),
      }));
    }
  };

  useEffect(() => {
    if (isAccountCreatedOrEdited) {
      setIsModalOpen(false);
      dispatch(fetchRepaymentsSourceAccounts({ organizationID }));
    }
  }, [isAccountCreatedOrEdited]);

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    values,
    handleSubmit,
    resetForm,
    validateForm,
    isSubmitting,
    setSubmitting,
    isValid,
    setValues,
  } = formik;

  const { isFormChanged, setDefaultValues } = useIsFormChanged(values);

  const handleModalClose = () => {
    resetForm();
    setIsModalOpen(false);
  };

  const handleSubmitExtended = () => {
    validateForm(values)
      .then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          handleSubmit();
        }
      });
  };

  useEffect(() => {
    if (isModalOpen) {
      setSubmitting(false);
      resetForm();
      setDefaultValues(initialValues);
      setValues({
        ...values,
        ...initialValues,
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (error?.message || error?.messageKey) {
      setSubmitting(false);
    }
  }, [error]);

  return (
    <Layer index={400}>
      <BankAccountFormContext.Provider value={formik}>
        <form>
          <AppModal
            modal={modalName}
            title={modalTitle}
            cancelBtnText={t('common:cancel')}
            onClose={handleModalClose}
            actionBtnText={t('common:save')}
            onAction={handleSubmitExtended}
            isCloseDisabled={isSubmitting}
            isActionDisabled={isSubmitting || !isValid || !isFormChanged}
          >
            <Loader active={false} />

            <Grid
              gridColumns={12}
              gridMargins={18}
              align={ALIGNMENT.start}
            >
              <AppInput
                showStar
                name="routingNumber"
                inputProps={{
                  id: 'routingNumber',
                  autoComplete: 'off',
                }}
                formControlProps={{
                  htmlFor: 'routingNumber',
                }}
                label={t('organizations:bankDetails.bankAccounts.routingNumber')}
                cellSpan={[12, 6]}
                context={BankAccountFormContext}
              />
              <AppInput
                showStar
                name="accountNumber"
                inputProps={{
                  id: 'accountNumber',
                  autoComplete: 'off',
                }}
                formControlProps={{
                  htmlFor: 'accountNumber',
                }}
                label={t('organizations:bankDetails.bankAccounts.accountNumber')}
                cellSpan={[12, 6]}
                context={BankAccountFormContext}
              />
            </Grid>

            <Grid
              gridColumns={12}
              gridMargins={18}
              align={ALIGNMENT.start}
            >
              <AppInput
                showStar
                name="legalName"
                inputProps={{
                  id: 'legalName',
                  autoComplete: 'off',
                }}
                formControlProps={{
                  htmlFor: 'legalName',
                }}
                label={t('organizations:bankDetails.bankAccounts.legalName')}
                cellSpan={[12, 6]}
                context={BankAccountFormContext}
              />
              <AppInput
                name="nickname"
                inputProps={{
                  id: 'nickname',
                  autoComplete: 'off',
                }}
                formControlProps={{
                  htmlFor: 'nickname',
                }}
                label={t('organizations:bankDetails.bankAccounts.accountDisplayName')}
                cellSpan={[12, 6]}
                context={BankAccountFormContext}
              />
            </Grid>

            <Grid
              gridColumns={12}
              gridMargins={18}
              align={ALIGNMENT.start}
            >
              <AppInput
                name="repaymentStatement"
                inputProps={{
                  id: 'repaymentStatement',
                  autoComplete: 'off',
                }}
                formControlProps={{
                  htmlFor: 'repaymentStatement',
                }}
                label={t('organizations:bankDetails.bankAccounts.repaymentStatement')}
                cellSpan={[12, 6]}
                context={BankAccountFormContext}
              />
            </Grid>
          </AppModal>
        </form>
      </BankAccountFormContext.Provider>
    </Layer>
  );
};

export default memo(OrganizationFormBankAccountModal);
